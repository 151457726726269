
export default {
    data() {
        return {
            charherStatusList: [
                {
                    text: '全部',
                    value: 0
                },
                {
                    text: '待审核',
                    value: 1
                },
                {
                    text: '待内勤审核',
                    value: 2
                },
                {
                    text: '待开票审核',
                    value: 3
                },
                {
                    text: '已审核',
                    value: 4
                },
                {
                    text: '已驳回',
                    value: 5
                },
            ]
        }
    },
    computed: {
        mixin_isPhone() {
            return this.$store.state.isPhone
        },
        mixin_userInfo() {
            return this.$store.state.userInfo
        },
        mixin_companyInfo() {
            return this.$store.state.currentCompanyInfo
        }
    },
    methods: {
        // 数字千分位处理
        mixin_formatterFn(value) {
            if (!value) return value
            let numstr = String(value),
                numList = numstr.split('.'),
                firstNum = numList[0],
                endNum = numList[1] || '',
                reg = /(\d)(?=(?:\d{3})+$)/g,
                nNum = firstNum.replace(reg, '$1,')
            if (endNum.length > 2) {
                endNum = endNum.slice(0, 2)
            }
            return nNum + (numstr.indexOf('.') > -1 ? '.' + endNum : '')
        },
        mixin_clone(info) {
            if (typeof info != 'object') return info
            return JSON.parse(JSON.stringify(info))
        },
        mixin_later(flag, commonText = '', fn = function() {}) {
            this.$toast[flag ? 'success' : 'fail'](commonText)
            flag && setTimeout(() => {
                fn && fn()
            }, 2500);
        }
    },
}