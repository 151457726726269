import axios from 'axios';
import qs from 'qs';
import Vue from 'vue';
import {
  Toast
} from 'vant';
Vue.use(Toast);
import {
  getQueryVariable
} from '../utils/utils.js';
import store from '../store'
const token = window.sessionStorage.getItem("token")
axios.defaults.timeout = 50000;
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/api' : `${window.location.origin}`
console.log(token, '------')
// axios.defaults.headers.common['X-Auth0-Token'] = token
axios.defaults.headers.common['Authorization'] = token
axios.defaults.headers['Content-Type'] = "application/json;charset=UTF-8"

// axios.defaults.headers['X-Auth0-Token'] = token
axios.defaults.headers['Authorization'] = token
axios.interceptors.request.use((config) => {
  if (window.sessionStorage.getItem("isQrcode")) {
    store.commit('updateLoading', false)
  } else {
    store.commit('updateLoading', true)
  }
  return config
}, (err) => {
  return Promise.reject(err)
})
axios.interceptors.response.use((response) => {

  store.commit('updateLoading', false)
  console.log(response, '===')
  if (response.status === 200 && response.data.code == 200) {

  } else if (response.data.code == 401) {
    console.log(response, 'response--')
    // window.location.href = `${window.location.origin}/api/wechat/authorize?a=${window.sessionStorage.getItem("activityId")}`
  } else if (response.data.code == 403) {
    console.log(response, 'response--')
    // window.location.href = `${window.location.origin}/api/wechat/authorize?a=${window.sessionStorage.getItem("activityId")}`
  } else {
    Toast.fail(response.data.msg);
  }
  return response.data
}, (error) => {
  if (error && error.stack.indexOf('timeout') > -1) {
    Toast('请求超时')
  }
  return Promise.reject(error)
})



//不带accessToken请求
export const axiosGet = async (url, params = {}) => {
  let res = await axios.get(url, {
    params: params
  });
  if (res && res.status == 200) {
    return res.data;
  } else {
    return res;
  }
}
// url带参数get
export const get_json = async (url, params = {}) => {
  let token
  if (process.browser) {
    token = sessionStorage.getItem('userinfo') ? JSON.parse(sessionStorage.getItem('userinfo')).token : ""
  }
  let res = await axios.get(url, {
    headers: {
      'Token': token,
    },
  });
  if (res && res.status == 200) {
    return res.data;
  } else {
    return res;
  }
}
//带accessToken请求
export const axiosHeaderTokenGet = async (url, params = {}, accessToken) => {
  let res = await axios.get(url, {
    headers: {
      'Token': accessToken,
    },
    params: params
  })
  if (res && res.status == 200) {
    return res.data;
  } else {
    return res;
  }
}

export const post_json = async (url, params = {}) => {
  let res = await axios({
    method: "post",
    url: url,
    data: params,
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}

export const post_del = async (url, params = {}) => {
  let res = await axios({
    method: "delete",
    url: url,
    params: params,
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}

export const post_params = async (url, params = {}) => {
  let res = await axios({
    method: "post",
    url: url,
    params: params,
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}
// 文档流下载处理
export const post_blob = async (url, params = {}) => {
  let res = await axios({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
    responseType: "blob",
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}

export const put_json = async (url, params = {}) => {
  let res = await axios({
    method: "put",
    url: url,
    data: params,
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}
export const axiosPost = async (url, params = {}) => {
  let res = await axios({
    method: "post",
    url: url,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: qs.stringify(params),
  })
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}
export const axiosHeaderTokenFormDataPost = async (url, params = {}, accessToken) => {
  let formData = new FormData();
  for (let key in params) {
    formData.append(key, params[key]);
  }
  let res = await axios.post(url, formData, {
    headers: {
      'Access-Token': accessToken,
      'Content-Type': 'application/x-www-form'
    }
  })
  if (res && res.status == 200) {
    return res.data;
  } else {
    return res;
  }
}
export const axiosFormDataPost = async (url, params = {}) => {
  let formData = new FormData();
  for (let key in params) {
    formData.append(key, params[key]);
  }
  let res = await axios.post(url, formData);
  if (res && res.status == 200) {
    return res.data;
  } else {
    return res;
  }
}

export const axiosFormDataPostAll = async (url, params = {}) => {
  let formData = new FormData();
  for (let key in params) {
    formData.append(key, params[key]);
  }
  console.log(await axios.post(url, formData));
  return await axios.post(url, formData);
}

/*多文件表单提交*/
export const formDataPostFiles = async (url, info = {}, filesList = [{
  key: '',
  list: []
}]) => {

  let formData = new FormData()
  for (let key in info) {
    formData.append(key, info[key])
  }
  filesList.forEach(v => {
    v.list.forEach(k => {
      formData.append(v.key, k)
    })
  })
  let res = await axios.post(url, formData)
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}

export const formDataPostFiles_ = async (url, info = {}, filesKeys = []) => {
  let formData = new FormData()
  for (let key in info) {
    let flag = true
    filesKeys.forEach(v => {
      if (v === key) {
        flag = false
        info[key].forEach(k => {
          formData.append(key, k)
        })
      }
    })
    if (flag) {
      formData.append(key, info[key])
    }
  }
  let res = await axios.post(url, formData)
  if (res && res.status == 200) {
    return res.data
  } else {
    return res
  }
}