// 获取url参数
export const getQueryVariable = (key) => {
    let infoStr = (window.location.href.split("?") || [])[1] || "",
        listStrArr = infoStr.split("&") || [],
        info = listStrArr.reduce((data, strItem) => {
            let valList = strItem.split("=");
            data[valList[0]] = valList[1];
            return data;
        }, {});
    return key ? info[key] : key;
};
export const formatDate = (date) => {
    var date = new Date(date);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    return `${YY}-${MM}-${DD}`;
}
// 算出2个时间天数
export const getDays = (strDateStart, strDateEnd) => {
    var strSeparator = "-"; //日期分隔符
    var oDate1;
    var oDate2;
    var iDays;
    oDate1 = strDateStart.split(strSeparator);
    oDate2 = strDateEnd.split(strSeparator);
    var strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
    var strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
    iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24) //把相差的毫秒数转换为天数
    return iDays;
}