<template>
    <div @click="$emit('click')" class="updowen-wrap c-center">
        <i class="iconfont s32 c-default icon-shouqi2x"  :class="{down: type === 'down'}"></i>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'up'
            }
        }
    }
</script>

<style lang="scss" scoped>
.updowen-wrap{
    position: absolute;
    width: 80px;
    height: 60px;
    right: 0;
    top: 0;
    z-index: 2;
    .iconfont{   
        transition: transform .5s;
        &.down{ 
            transform: rotate(180deg);
        }
    }
   
}
</style>