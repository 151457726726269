import Vue from 'vue';
import Vuex from 'vuex'; //引入 vuex
Vue.use(Vuex); //使用 vuex
const state = {
  userInfo: {},
  token: '',
  showLoading: false
}
const mutations = {
  // 请求loding
  updateLoading(state, showLoading) {
    state.showLoading = showLoading
  },
  setUserInfo(state, data) {
    window.localStorage.setItem("userinfo", JSON.stringify(data))
    let dataNew = Object.assign({}, state.userInfo, data)
    state.userInfo = dataNew
  },
  setToken(state, data) {
    window.localStorage.setItem("token", data)
    state.token = data
  },
  getToken(state) {
    let token = window.localStorage.getItem("token")
    console.log(token)
    state.token = token
    return state.token
  }
}
const getters = {

}
const action = {

}
export default new Vuex.Store({
  state,
  mutations,
  getters,
  action
})