import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 按需引入vant组件
import './utils/vantComponents.js'
import 'amfe-flexible/index.js'
import './common/css/common.scss';
import './common/css/table.scss';
import {
  commonChangeHtmlFontSize
} from './utils/init.js'

import updown from '@/components/updown.vue'
import Vconsole from 'vconsole'

if (process.env.NODE_ENV === 'development') {
  const vConsole = new Vconsole()
  Vue.use(vConsole)
}

Vue.component('updown', updown)
commonChangeHtmlFontSize()
import Mixin from "@/mixins/index.js";
Vue.mixin(Mixin)
Vue.config.productionTip = false

Vue.prototype.$ELEMENT = {
  size: 'small'
};
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')