import Vue from 'vue'
import {
    Icon,
    Button,
    Form,
    Search,
    Loading,
    Field,
    SwipeCell,
    Cell,
    Uploader,
    Switch,
    RadioGroup,
    DatetimePicker,
    Radio,
    Dialog,
    List,
    TreeSelect,
    Toast,
    Tab,
    Tabs,
    PullRefresh,
    Checkbox,
    CheckboxGroup,
    ActionSheet,
    Popup,
    Picker,
    calendar,
    Swipe,
    SwipeItem,
    NoticeBar,
    Lazyload,
    Stepper,
    ImagePreview
} from 'vant';
Vue.use(Button)
    .use(Form)
    .use(NoticeBar)
    .use(Search)
    .use(PullRefresh)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(ActionSheet)
    .use(Uploader)
    .use(Picker)
    .use(Dialog)
    .use(Toast)
    .use(Tab)
    .use(Tabs)
    .use(Field)
    .use(TreeSelect)
    .use(Loading)
    .use(List)
    .use(RadioGroup)
    .use(Radio)
    .use(Popup)
    .use(SwipeCell)
    .use(Cell)
    .use(Switch)
    .use(DatetimePicker)
    .use(Icon)
    .use(calendar)
    .use(Swipe)
    .use(SwipeItem)
    .use(Lazyload)
    .use(Stepper)
    .use(ImagePreview)