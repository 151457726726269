function getViewSizeWithScrollbar() {//包含滚动条 
    if (window.innerWidth) {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    } else if (document.documentElement.offsetWidth == document.documentElement.clientWidth) {
        return {
            width: document.documentElement.offsetWidth,
            height: document.documentElement.offsetHeight
        }
    } else {
        return {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        }
    }
}
window.onresize = function () {
    commonChangeHtmlFontSize()
}
// 设置最大字体大小 
export function commonChangeHtmlFontSize() { 
    let wInfo = getViewSizeWithScrollbar() 
    if (wInfo.width >= 425) { 
        document.querySelector('html').style.fontSize = '41.4px'
    }  
}
