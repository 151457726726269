import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'custom',

        component: () => import('../views/hh/index.vue'),
        meta: {
            title: '活动页面'
        }
    },
    {
        path: '/home',
        name: 'Home',

        component: () => import('../views/home/index.vue'),
        meta: {
            title: '活动页面'
        }
    },
    {
        path: '/list',
        name: 'list',

        component: () => import('../views/list/index.vue'),
        meta: {
            title: '广州逸林假日酒店'
        }
    },
    {
        path: '/pay',
        name: 'pay',

        component: () => import('../views/pay/index.vue'),
        meta: {
            title: '支付订单'
        }
    },
    {
        path: '/paySuccessful',
        name: 'paySuccessful',

        component: () => import('../views/paySuccessful/index.vue'),
        meta: {
            title: '支付结果'
        }
    },
    {
        path: '/payList',
        name: 'payList',

        component: () => import('../views/payList/index.vue'),
        meta: {
            title: '订单列表'
        }
    },
    {
        path: '/payDetails',
        name: 'payDetails',

        component: () => import('../views/payDetails/index.vue'),
        meta: {
            title: '订单详情'
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0;
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})

export default router