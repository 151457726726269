import {
    axiosGet,
    get_json,
    axiosHeaderTokenGet,
    axiosPost,
    post_json,
    put_json,
    post_params
} from '../axiosUtils.js'
// 活动详情
export const activityDetail = async (params) => {
    let url = '/api/activity/detail';
    return await axiosGet(url, params);
}

// 活动报名
export const activityApply = async (params) => {
    let url = '/api/activity/apply';
    return await post_json(url, params);
}
// 酒店预订页面
export const listAll = async (params) => {
    let url = "/api/activity/book";
    return await axiosGet(url, params);
}

//酒店预订提交订单接口
export const commit = async (params) => {
    let url = "/api/order/commit";
    return await post_json(url, params);
}
// 订单列表
export const orderList = async (params) => {
    let url = '/api/order/list';
    return await axiosGet(url, params);
}

// 订单详情
export const orderDetail = async (params) => {
    let url = '/api/order/detail';
    return await axiosGet(url, params);
}

// 申请发票
export const applyInvoice = async (params) => {
    let url = '/api/order/applyInvoice';
    return await post_json(url, params);
}

// 微信支付
export const unifiedOrder = async (params) => {
    let url = '/api/pay/unifiedOrder';
    return await axiosGet(url, params);
}

// 网页授权
export const authorize = async (params) => {
    let url = '/api/wechat/authorize';
    return await axiosGet(url, params);
}
// 是否关注了公众号
export const isFocuson = async (params) => {
    let url = '/api/wechat/isAttention';
    return await axiosGet(url, params);
}

// 分享获取信息
export const getWxConfig = async (params) => {
    let url = '/api/wechat/getWxConfig';
    return await axiosGet(url, params);
}

