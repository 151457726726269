<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
    <div class="load_cover" v-if="showLoading">
      <!-- <van-loading /> -->
      <van-loading color="#1989fa"></van-loading>
    </div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getWxConfig, activityDetail } from "./api/home/index.js";
import { getQueryVariable } from "./utils/utils";

export default {
  name: "App",
  computed: {
    ...mapState(["showLoading"]),
  },
  data() {
    return {
      isRouterAlive: true,
      activityId: "",
    };
  },
  mounted() {
    window.document.title = "";
    console.log(
      window.location.href,
      getQueryVariable("a"),
      this.$route.query.a,
      "APP.vue"
    );
    this.activityId = getQueryVariable("a");
    this.activityDetail();
  },
  methods: {
    // 获取活动详情
    async activityDetail() {
      let data = {
        activityId: this.activityId,
      };
      let res = await activityDetail(data);
      if (res.code == 200) {
        this.list = res.data;
        await this.wxPosition();
      }
    },
    async wxPosition() {
      let url = window.location.origin;
      let dizhi = `https://book.easelandhotel.com/home?a=${this.activityId}`;
      let urlImg =
        this.list.pics.length > 0
          ? this.list.pics[0]
          : `${url}/files/2021/09/25/logo.png`;
      let _this = this;
      let data = {
        a: this.activityId,
      };
      let res = await getWxConfig(data);
      if (res) {
        if (res.code == 200) {
          console.log(res, "进来了");
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "onMenuShareAppMessage",
              "onMenuShareTimeline",
              "updateAppMessageShareData", //加的新的接口
              "updateTimelineShareData ", //加的新的接口
            ],
          });
          setTimeout(() => {
            wx.ready(async () => {
              console.log("jsdk授权成功", url);
              wx.checkJsApi({
                jsApiList: [
                  "onMenuShareTimeline",
                  "onMenuShareAppMessage",
                  "updateAppMessageShareData", //加的新的接口
                  "updateTimelineShareData ", //加的新的接口
                ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function (res) {
                  console.log(res, "checkJsApi");
                  // 以键值对的形式返回，可用的api值true，不可用为false
                  // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                },
              });
              wx.onMenuShareAppMessage &&
                wx.onMenuShareAppMessage({
                  title: _this.list.name, // 分享标题
                  desc: _this.list.introduction,
                  link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
                  imgUrl: urlImg, // 分享图标
                  success: function () {
                    // 用户点击了分享后执行的回调函数
                    console.log("分享成功onMenuShareAppMessage");
                  },
                  cancel: function () {
                    console.log("分享到朋友失败onMenuShareAppMessage");
                  },
                });

              wx.onMenuShareTimeline &&
                wx.onMenuShareTimeline({
                  title: _this.list.name, // 分享标题
                  desc: _this.list.introduction,
                  link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
                  imgUrl: urlImg, // 分享图标
                  success: function () {
                    // 用户点击了分享后执行的回调函数
                    console.log("分享成功onMenuShareTimeline");
                  },
                  cancel: function () {
                    console.log("分享到朋友失败onMenuShareTimeline");
                  },
                });

              wx.updateAppMessageShareData &&
                wx.updateAppMessageShareData({
                  title: _this.list.name, // 分享标题
                  desc: _this.list.introduction,
                  link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
                  imgUrl: urlImg, // 分享图标
                  success: async (res) => {
                    console.log("updateAppMessageShareData成功");
                  },
                });

              wx.updateTimelineShareData &&
                wx.updateTimelineShareData({
                  title: _this.list.name, // 分享标题
                  desc: _this.list.introduction,
                  link: dizhi, // `${url}/api/wechat/authorize?a=${_this.activityId}`,
                  imgUrl: urlImg, // 分享图标
                  success: async (res) => {
                    console.log("updateTimelineShareData成功");
                  },
                });
            });
          }, 1000);

          wx.error(async (res) => {
            console.log("jsdk授权失败");
            console.log(9999);
          });
        }
      }
    },
  },
};
</script>
<style>
#app {
  background: #f1f1f1;
}
.van-loading__circular circle {
  stroke-width: 5px;
}
.van-loading {
  width: 40px;
  height: 40px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
}
.load_cover {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
}
html,
body {
  width: 100%;
}
</style>
